<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="steps-wrap">
      <b-container>
        <b-row>
          <b-col>
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('orderPaymentMethodViewShoppingCart')"></el-step>
              <el-step :title="$t('orderPaymentMethodConfirmOrderInfo')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderSubmitSuccess')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderPaymentSuccess')"></el-step>
            </el-steps>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="con-wrap">
      <b-container>
        <b-row>
          <b-col>
            <div class="shop-cart-items" v-if="shopCartItems.length > 0">
              <div class="item-title-wrap">
                <div class="item">商品</div>
                <div class="item">单价(元)</div>
                <div class="item">数量</div>
                <div class="item">小计</div>
                <div class="item">操作</div>
              </div>
              <div class="items-wrap">
                <div class="item-con" v-for="(item, index) in shopCartItems" :key="index"
                  @click.stop="selectGoods($event, index)">
                  <div class="item">
                    <el-checkbox :value="item.checked"></el-checkbox>
                    <div class="pic" @click.stop="toProductDetail(item)">
                      <img :src="item.img_path" :alt="item.goods_name">
                    </div>
                    <div class="product-detail">
                      <div class="name" @click.stop="toProductDetail(item)">{{item.goods_name}}</div>
                      <div class="params">{{item.goods_parameter}}</div>
                    </div>
                  </div>
                  <div class="item">￥{{item.goods_price}}</div>
                  <div class="item">
                    <el-input-number v-model="item.goods_number" @change="quantityChange(item)"
                      @click.native.stop.prevent :min="1" :max="100" size="mini"></el-input-number>
                  </div>
                  <div class="item">￥{{item.amount_price}}</div>
                  <div class="item">
                    <span @click.stop="delShopCartItem(item)">删除</span>
                  </div>
                </div>
                <div class="operation-btn-wrap">
                  <el-button type="warning" @click="shopCartExportList">导出清单</el-button>
                  <el-button type="info" @click="openOrderAgreementDialog">导出合同</el-button>
                </div>
                <div class="tip-bar">
                  <el-checkbox v-model="allChecked" @change="checkedAllChange">全选</el-checkbox>
                  <el-checkbox indeterminate disabled @click.native="delCheckedShopCartItem">清空选中商品</el-checkbox>
                  <div class="select-option">
                    <div class="select-product">已选商品{{totalPrice.quantity}}件</div>
                    <div class="total-price">
                      合计
                      <span>￥{{totalPrice.sum}}</span>
                    </div>
                  </div>
                  <el-button type="danger" @click="shopCartItemsSettlement">去结算</el-button>
                </div>
              </div>
            </div>
            <div class="shop-cart-empty" v-else>
              <img src="@/../public/icon/userCenter/shopCart/none.jpg" alt="">
              <div>亲，购物车里空空如也，赶紧<span @click="toHome">去逛逛吧>></span></div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="order-agreement">
      <el-dialog
        title="订单协议"
        :visible.sync="openOrderAgreement"
        width="60%"
        ref="dialog"
        :show-close="printEl"
        center>
        <div class="order-agreement-con">
          <el-form :model="form" label-width="96px" size="mini">
            <div class="item-title" contenteditable>一，协议主体</div>
            <div class="item-con">
              <div class="item">
                <el-form-item label="甲方：(业主)" contenteditable>
                  <el-input v-model="form.owner"></el-input>
                </el-form-item>
              </div>
              <div class="item" >
                <el-form-item label="乙方：(服务方)" contenteditable>
                  <el-input v-model="form.serviceParty"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item-title" contenteditable>二，订单明细</div>
            <div class="item-con">
              <table contenteditable>
                <thead>
                  <tr>
                    <th>序号</th>
                    <th>产品图片</th>
                    <th>商品型号</th>
                    <th>材质</th>
                    <th>规格</th>
                    <th>数量</th>
                    <th>单价(￥)</th>
                    <th>总价(￥)</th>
                    <th>商品描述</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in printArr" :key="index">
                    <td>{{index}}</td>
                    <td>
                      <div class="pic">
                        <img :src="item.img_path" :alt="item.goods_name">
                      </div>
                    </td>
                    <td>{{item.goods_marque}}</td>
                    <td>{{item.goods_parameter.split(',')[1]}}</td>
                    <td>{{item.goods_parameter.split(',')[0]}}</td>
                    <td>{{item.goods_number}}</td>
                    <td>{{item.goods_price}}</td>
                    <td>{{item.amount_price}}</td>
                    <td>
                      <div class="des">{{item.goods_name}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="item-title" contenteditable>三，付款方法</div>
            <div class="item-con-column">
              <div class="payment-method" contenteditable>
                付款方式：
                <el-radio-group v-model="form.paymentMethod">
                  <el-radio :label="1">现金支付</el-radio>
                  <el-radio :label="2">银行转账</el-radio>
                </el-radio-group>
              </div>
              <div class="pay">
                <div class="item">
                  <el-form-item label="预付款：" label-width="60px">
                    <el-input v-model="form.advanceCharge"></el-input>
                  </el-form-item>
                </div>
                <div class="item">
                  <el-form-item label="未付款：" label-width="60px">
                    <el-input v-model="form.unpaid"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="tip" contenteditable>备注：确认收齐全款之后，方可安排送货安装。</div>
            </div>
            <div class="item-title" contenteditable>四，验收&交货时间约定</div>
            <div class="form-inline">
              <div class="time-wrap" contenteditable>
                1，订单确认时间：
                <el-form-item label="年" label-width="14px" >
                  <el-input v-model="form.orderConfirmYear"></el-input>
                </el-form-item>
                <el-form-item label="月" label-width="14px" >
                  <el-input v-model="form.orderConfirmMonth"></el-input>
                </el-form-item>
                <el-form-item label="日" label-width="14px" >
                  <el-input v-model="form.orderConfirmDay"></el-input>
                </el-form-item>
              </div>
              <div class="time-wrap" contenteditable>
                2，施工/配送时间：
                <el-form-item label="年" label-width="14px" >
                  <el-input v-model="form.deliveryYear"></el-input>
                </el-form-item>
                <el-form-item label="月" label-width="14px" >
                  <el-input v-model="form.deliveryMonth"></el-input>
                </el-form-item>
                <el-form-item label="日" label-width="14px" >
                  <el-input v-model="form.deliveryDay"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item-title" contenteditable>五，售后服务</div>
            <div class="form-inline">
              <div class="time-wrap" contenteditable>
                1，质保期：
                <el-form-item label-width="0">
                  <el-input v-model="form.qualityGuaranteePeriod"></el-input>
                </el-form-item>
                个月
              </div>
              <div class="time-wrap service-phone" contenteditable>
                2，售后服务电话：
                <el-form-item label-width="0">
                  <el-input v-model="form.servicePhone"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item-con" contenteditable>3、甲方在工厂订单未下单前如需退换货，则需在退换货的同时向乙方返还需退换货商品购买时附赠的赠品或其他性质的优惠权益。如附赠的赠品无法返还乙方时，甲方应按赠品购入成本为标准返还乙方，方能办理退换货手续。办理退换时， 业主需承担1%刷卡手续费。</div>
            <div class="item-con" contenteditable>4、甲方办理退款手续，相应款项将于30个工作日内打入业主提供的账户；</div>
            <div class="item-con" contenteditable>5、具体产品&服务要求可以附件形式体现，本合作协议具有同等效益，受甲乙双方认可后生效。</div>
            <div class="item-con">
              <div class="item" contenteditable>
                <el-form-item label="甲方：(业主)">
                  <el-input v-model="form.ownerSign"></el-input>
                </el-form-item>
              </div>
              <div class="item" contenteditable>
                <el-form-item label="乙方：(服务方)">
                  <el-input v-model="form.servicePartySign"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item-con" contenteditable>
              <div class="item">
                <el-form-item label="交付地址：">
                  <el-input v-model="form.ownerDeliveryAddress"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="交付地址：">
                  <el-input v-model="form.servicePartyDeliveryAddress"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="item-con" contenteditable>
              <div class="item">
                <el-form-item label="电话：">
                  <el-input v-model="form.ownerPhone"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="电话：">
                  <el-input v-model="form.servicePartyPhone"></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-inline direction">
              <div class="item">
                <div class="time-wrap" contenteditable>
                  签约日期：
                  <el-form-item label="年" label-width="14px" >
                    <el-input v-model="form.ownerYear"></el-input>
                  </el-form-item>
                  <el-form-item label="月" label-width="14px" >
                    <el-input v-model="form.ownerMonth"></el-input>
                  </el-form-item>
                  <el-form-item label="日" label-width="14px" >
                    <el-input v-model="form.ownerDay"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="item">
                <div class="time-wrap" contenteditable>
                  签约日期：
                  <el-form-item label="年" label-width="14px" >
                    <el-input v-model="form.servicePartyYear"></el-input>
                  </el-form-item>
                  <el-form-item label="月" label-width="14px" >
                    <el-input v-model="form.servicePartyMonth"></el-input>
                  </el-form-item>
                  <el-form-item label="日" label-width="14px" >
                    <el-input v-model="form.servicePartyDay"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="item-com" contenteditable>注：为维护消费者、经营者合法权益，根据国家相关法律及规定，经甲乙双方协商签订本合同，并共同遵守。本合同一式两份，双方各执一份。</div>
          </el-form>
          
          <!-- @click="exportContract" -->
        </div>
        <span slot="footer" v-if="printEl" class="dialog-footer">
          <el-button class="no-print" type="warning" @click="exportContract">导 出 合 同</el-button>
        </span>
      </el-dialog>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  import html2canvas from 'html2canvas';
  import printJs from 'print-js';
  import domainUrl from "@/api/config";
  // import print from 'vue-print-nb'
  export default {
    components: {
      headNav,
      footerNav
    },
    // directives: {
    //   print   
    // },
    data() {
      return {
        active: 1,
        allChecked: false,
        token: "",
        currentPage: 1,
        shopCartItems: [],
        openOrderAgreement: false,
        form: {
          owner: "",
          serviceParty: "",
          paymentMethod: 1,
          advanceCharge: "",
          unpaid: "",
          orderConfirmYear: "",
          orderConfirmMonth: "",
          orderConfirmDay: "",
          deliveryYear: "",
          deliveryMonth: "",
          deliveryDay: "",
          qualityGuaranteePeriod: "",
          servicePhone: "",
          ownerSign: "",
          servicePartySign: "",
          ownerDeliveryAddress: "",
          servicePartyDeliveryAddress: "",
          ownerPhone: "",
          servicePartyPhone: "",
          ownerYear: "",
          ownerMonth: "",
          ownerDay: "",
          servicePartyYear: "",
          servicePartyMonth: "",
          servicePartyDay: ""
        },
        printArr: [],
        printEl: true
      }
    },
    created() {
      this.init();
    },
    computed: {
      // 计算总价格
      totalPrice() {
        let sum = 0,
          quantity = 0;
        this.shopCartItems.forEach(item => {
          if (item.checked) {
            sum += parseFloat(item.goods_number * item.goods_price);
            quantity += item.goods_number;
          }
        })
        return {
          sum: sum.toFixed(2),
          quantity
        }
      }
    },
    methods: {
      init() {
        this.token = localStorage.getItem("token");
        this.getShopCartList();
      },
      // 获取购物车商品列表
      getShopCartList() {
        // 检测用户是否登录
        if (!dump.checkLogin()) {
          return false;
        }
        this.$api.shopCartList({
          currentPage: this.currentPage,
          token: this.token,
          shopId: this.cookie.getCookie("shopId")
        }).then(res => {
          if (res.status == 100) {
            this.shopCartItems = res.data.carts;
            this.shopCartItems.forEach(item => {
              this.$set(item, "checked", false)
            })
          }
        }).catch(err => console.error(err));
      },
      // 跳转到商城
      toHome() {
        const params = {
          type: 3,
          link: "/mallIndex"
        }
        dump.link(params);
      },
      // 跳转到商品详情
      toProductDetail(obj) {
        const params = {
          type: 1,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        }
        dump.link(params);
      },
      // 增加/减少商品数量 
      quantityChange(obj) {
        this.$api.goodsQuantityChange({
          shoppingcart_id: obj.shoppingcart_id,
          goods_number: obj.goods_number,
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            obj.amount_price = (obj.goods_number * obj.goods_price).toFixed(2);
          }
        }).catch(err => console.error(err));
      },
      // 选中商品
      selectGoods(e, i) {
        // 因为原生click事件会执行两次，第一次在label标签上，第二次在input标签上，故此处理
        if (e.target.tagName === "INPUT") return;
        const checkedArr = [];
        this.shopCartItems[i].checked = !this.shopCartItems[i].checked;
        this.shopCartItems.forEach(item => {
          if (item.checked === true) {
            checkedArr.push(item)
          }
        })

        if (checkedArr.length === this.shopCartItems.length) {
          this.allChecked = true;
        } else {
          this.allChecked = false;
        }
      },
      // 全选或取消全选
      checkedAllChange(val) {
        this.shopCartItems.forEach(item => {
          item.checked = val;
        })
      },
      // 删除购物车中的某个商品
      delShopCartItem(obj) {
        this.$confirm('您确定要删除该商品吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.delShopCartItem({
            shoppingcart_id: obj.shoppingcart_id,
            token: this.token
          }).then(res => {
            if (res.status == 100) {
              this.getShopCartList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          }).catch(err => console.error(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 删除购物车中所选中的商品
      delCheckedShopCartItem() {
        const checkedArr = [],
          shopCartIdArr = [];
        this.shopCartItems.forEach(item => {
          if (item.checked === true) {
            checkedArr.push(item)
          }
        })

        if (!checkedArr.length > 0) return this.$message.error('请选中要删除的商品');

        checkedArr.forEach(item => {
          shopCartIdArr.push(item.shoppingcart_id);
        })

        this.$confirm('您确定要删除所选中商品吗?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.delShopCartItems({
            shoppingcartIds: shopCartIdArr.join(","),
            token: this.token
          }).then(res => {
            if (res.status == 100) {
              this.getShopCartList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          }).catch(err => console.error(err))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 结算
      shopCartItemsSettlement() {
        const idArr = [];
        this.shopCartItems.forEach(item => {
          if (item.checked) {
            idArr.push(item.shoppingcart_id);
          }
        })

        if (idArr.length === 0) return this.$message.error('请选择要结算的商品');

        this.$api.settlement({
          shoppingcartIds: idArr.join(","),
          token: this.token
        }).then(res => {
          if (res.status == 100) {
            this.getShopCartList();
            this.$message({
              type: 'success',
              message: '结算成功!'
            });
            const params = {
              type: 1,
              link: "/orderSettlement",
              params: {
                orderid: res.data.order_id
              }
            }
            
            dump.link(params);
          }
          if (res.status == 103) {
            this.$message.error('请重新登录');
            const params = {
              type: 1,
              link: "/login"
            }
            dump.link(params);
          }
        }).catch(err => console.error(err));
      },
      // 导出清单
      shopCartExportList(){
        const checkedArr = [],
              exportListArr = [];
        let totalPrice = 0,
            totalNumber = 0;
        this.shopCartItems.forEach(item => {
          if (item.checked === true) {
            checkedArr.push(item)
          }
        })
        
        if (!checkedArr.length > 0) return this.$message.error('请选择需要导出清单的商品');
        
        checkedArr.forEach(item => {
          const exportItem = {
            brandName: item.brand_name,
            company: "个",
            goodsType: item.label_name,
            makings: item.goods_parameter.split(',')[1],
            marque: item.goods_marque,
            norms: item.goods_parameter.split(',')[0],
            number: item.goods_number,
            picture: item.img_path,
            price: item.goods_price,
          }
          
          if(item.goods_parameter.split(',').length>2){
            exportItem.remarks = item.goods_parameter.split(',')[2];
          }
          
          totalPrice += parseFloat(item.total_price);
          totalNumber += item.goods_number;
          
          exportListArr.push(exportItem);
        })
        
        this.$api.exportList({
          paramList: JSON.stringify(exportListArr)
        }).then(res => {
          if(res.status == 100){
            let link = domainUrl + "/exportexcel/excelPlan.do?title=/&apartment=/&size=/&totalPrice=" + totalPrice + "&totalNumber=" + totalNumber;
            window.open(link);
          }
        }).catch(err => console.error(err));
      },
      // 点击打开/关闭导出合同弹窗
      openOrderAgreementDialog(){
        this.printArr = [];
        this.shopCartItems.forEach(item => {
          if (item.checked === true) {
            this.printArr.push(item)
          }
        })
        
        if (!this.printArr.length > 0) return this.$message.error('请选择要打印的商品');

        this.openOrderAgreement = !this.openOrderAgreement;
        
      },
      // 打印合同
      exportContract(){
        this.printEl = false;
        this.openOrderAgreementDialog();

        this.$nextTick(() => {
          const printContent = this.$refs.dialog.$el.children[0];
          
          // 获取dom 宽度 高度
          const width = printContent.clientWidth;
          const height = printContent.clientHeight;
          // 创建一个canvas节点
          const canvas = document.createElement('canvas');
          
          const scale = 2; // 定义任意放大倍数，支持小数；越大，图片清晰度越高，生成图片越慢。
          canvas.width = width * scale; // 定义canvas 宽度 * 缩放
          canvas.height = height * scale; // 定义canvas高度 *缩放
          canvas.style.width = width * scale + 'px';
          canvas.style.height = height * scale + 'px';
          canvas.getContext('2d').scale(scale, scale); // 获取context,设置scale
          
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动轴滚动的长度
          const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft; // 获取水平滚动轴的长度
          
          html2canvas(printContent, {
            canvas,
            backgroundColor: null,
            useCORS: true,
            windowHeight: document.body.scrollHeight,
            scrollX: -scrollLeft, // 解决水平偏移问题，防止打印的内容不全
            scrollY: -scrollTop
          }).then((canvas) => {
            const url = canvas.toDataURL('image/png')
            printJs({
              printable: url,
              type: 'image',
              documentTitle: '', // 标题
              style: '@page{size:auto;margin: 1cm 1cm 1cm 1cm;}' // 去除页眉页脚
            })
          }).catch(err => {
            console.error(err);
          })
        })
        
        this.$nextTick(() => {
          this.printEl = true;
        })
      
      }

    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/view/userCenter/shopCart/shopCart";
  @import "print.css";
</style>
